import React from "react";
import DetailCollection from "../../../component/detail-collection/DetailCollection";
import simonDetail1 from "../../../assets/simon-detail-1.png";
import simonDetail2 from "../../../assets/simon-detail-2.png";
import simonDetail3 from "../../../assets/simon-detail-3.png";

function SimonGame() {
    return(
        <DetailCollection 
            image1={simonDetail1}
            image2={simonDetail2}
            image3={simonDetail3}
            title={"Simon Game"}
            description={"Simon game website is a game using four LEDs which will flash alternately to form a certain pattern, where the player must remember the LED flash pattern and repeat it by pressing the button following the LED flash pattern. The higher the level the user reaches, the more patterns there will be to remember and if the user chooses the wrong LED, the game will end."}
            year={"2022"}
            role={"Full Stack Developer"}
            tools={"Atom, html, css, javascript, jquery."}
            link={"https://simon.rfstore.net"}
        />
    )
}

export default SimonGame;