import React from "react";
import DetailCollection from "../../../component/detail-collection/DetailCollection";
import KeeperDetail1 from "../../../assets/keeper-detail-1.png";
import KeeperDetail2 from "../../../assets/keeper-detail-2.png";
import KeeperDetail3 from "../../../assets/keeper-detail-3.png";

function GoogleKeepClone() {

    return(
        <DetailCollection 
            image1={KeeperDetail1}
            image2={KeeperDetail2}
            image3={KeeperDetail3}
            title={"Google Keep Clone"}
            description={"Google keep clone is a website created using React.js and has the function of storing notes that will later be written by the user. Users can add notes by filling in the title and content columns then pressing the 'Add' button. Users can also delete a previously saved note by pressing the 'Delete' button."}
            year={"2022"}
            role={"Front End Developer"}
            tools={"Codesandbox, html, css, javascript, react.js"}
            link={"https://keeper.rfstore.net"}
        />
    );

}

export default GoogleKeepClone;