import React from "react";
import Header from "../../component/header/Header";
import AllProject from "../../assets/all-project.png";
import "./Project.css";
import Collection from "../../component/collection/Collections";
import CollectionEgg from "../../assets/collection-egg.png";
import CollectionDiego from "../../assets/collection-diego.png";
import CollectionKeep from "../../assets/collection-keep.png";
import CollectionUnistore from "../../assets/collection-unistore.png";
import CollectionSimon from "../../assets/collection-simon.png";
import CollectionDrum from "../../assets/collection-drum.png";
import CollectionDiezy from "../../assets/collection-diezy.png";
import CollectionAppAdmin from "../../assets/collection-appadmin.png";
import CollectionWebgasys from "../../assets/collection-gasys.png";
import CollectionGrood from "../../assets/collection-grood.png";

function Project() {
  return (
    <div className="project">
      <Header classProject={"active"} />
      <div className="section1 flex z-0 gap-[4vw]">
        <img
          className="all-project-img"
          src={AllProject}
          alt="Gambar All Project"
        />
        {/* <div className="column1">
                    <div>
                    </div>
                </div> */}
        <div className="mt-[2vw] column2">
          <h1>PROJECT</h1>
          <div className="text-div">
            <p>
              My portfolio creates a complete picture of my skills and
              dedication in website development. Through a series of diverse
              projects, I have explored and brought to life a variety of
              concepts, from mini marketplace platforms to practical solutions
              in game creation. My portfolio is not just a collection of work,
              but rather an ongoing journey of creative exploration.
            </p>
          </div>
          <a href="#collection-project">
            <button>VIEW PROJECT</button>
          </a>
        </div>
      </div>

      {/* setion2 */}
      <div className="section2">
        <h1 className="title">COLLECTION</h1>

        <div id="collection-project" className="flex project-group">
          <a className="collection-1" href="/project/webgasys">
            <Collection
              image={CollectionWebgasys}
              title={"Website GASys"}
              description={"Website General Affair System"}
            />
          </a>

          <a className="collection-2" href="/project/grood">
            <Collection
              image={CollectionGrood}
              title={"Grood"}
              description={"Web Instant Feedback United Tractors"}
            />
          </a>

          <a className="collection-3" href="/project/egg-mini-store">
            <Collection
              image={CollectionEgg}
              title={"Egg Mini Store"}
              description={"Mini Marketplace Website"}
            />
          </a>
        </div>
        <div className="flex mt-[10vw] project-group">
          <a className="collection-4" href="/project/uni-store">
            <Collection
              image={CollectionUnistore}
              title={"Uni Store"}
              description={"Food Marketplace Website"}
            />
          </a>

          <a className="collection-5" href="/project/simon-game">
            <Collection
              image={CollectionSimon}
              title={"Simon Game"}
              description={"Short Term Memory Skills Game"}
            />
          </a>
          <a className="collection-6" href="/project/drum-kit">
            <Collection
              image={CollectionDrum}
              title={"Drum Kit"}
              description={"Drum Online Website"}
            />
          </a>
        </div>
        <div className="flex mt-[10vw] project-group">
          <a className="collection-7" href="/project/google-keep-clone">
            <Collection
              image={CollectionKeep}
              title={"Google Keep Clone"}
              description={"Keep Notes Website"}
            />
          </a>
          <a className="collection-8" href="/project/diezy">
            <Collection
              image={CollectionDiezy}
              title={"Diezy (Diet easy)"}
              description={"Website Helps to Live Healthy"}
            />
          </a>

          <a className="collection-9" href="/project/diego">
            <Collection
              image={CollectionDiego}
              title={"Diego (Digital Era Go)"}
              description={"Business Digitalization Support Services"}
            />
          </a>
        </div>

        <div className="flex mt-[10vw] project-group">
          <a className="collection-7" href="/project/app-admin">
            <Collection
              image={CollectionAppAdmin}
              title={"App Admin"}
              description={"CRUD application for admin management"}
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Project;
