import React, { useState } from "react";
import "./Header.css"
import Linkedin from "../../assets/Linkedin.png";
import Instagram from "../../assets/instagram.png";
import Whatsapp from "../../assets/whatsapp.png";
import LogoNama from "../../assets/logo-nama.png";

function Header(props) {
    const [showHeader, setShowHeader] = useState("max-[500px]:hidden");
    // const [overflow, setOverflow] = useState("")

    // const style = {
 
    //     // Adding media query..
    //     '@media (max-width: 500px)': {
    //       display: 'block',
    //     },
    // };


    function handleClick(event) {
        event.preventDefault();

        if(showHeader == "max-[500px]:hidden") {
            setShowHeader("max-[500px]:block");
            // document.body.classList.add('overflow-hidden');
        } else if(showHeader == "max-[500px]:block") {
            setShowHeader("max-[500px]:hidden");
            // document.body.classList.remove('overflow-hidden');
        }
    }

    return (
        <div className={"header px-[2vw] py-[1vw] bg-[#EEEEEE] shadow-[0px_0.25vw_0.25vw_0.25vw_rgba(0, 0, 0, 0.25)]"}>
            <img className="logo-nama" src={LogoNama} alt="Logo Nama" />
            <div className="social-div2">
                    <a className="social-image2 linkedIn2" href="https://www.linkedin.com/in/raefandy-fadila" target="_blank">
                        <img className="lindkedin" src={Linkedin} />
                    </a>
                    <a className="social-image2 instagram2" href="https://api.whatsapp.com/send/?phone=6287879564171&text&type=phone_number&app_absent=0" target="_blank">
                        <img className="instagram" src={Whatsapp} />
                    </a>
            </div>
            <button onClick={handleClick} className="bar-button">
                <i class="fa-solid fa-bars bar-icon"></i>
            </button>
            <div className={"header-right " + showHeader}>
                <a className="header-text" class={props.classHome} href="/">Home</a>
                {/* <a className="header-text" class={props.classResume} href="/resume">Resume</a> */}
                <a className="header-text" class={props.classProject} href="/project">Project</a>
                {/* <a className="header-text" class={props.classContact} href="/contact">Contact</a> */}
                <a className="line">I</a>
                <a className="social-image linkedIn" href="https://www.linkedin.com/in/raefandy-fadila" target="_blank">
                    <img className="lindkedin" src={Linkedin} />
                </a>
                <a className="social-image instagram" href="https://api.whatsapp.com/send/?phone=6287879564171&text&type=phone_number&app_absent=0" target="_blank">
                    <img className="instagram" src={Whatsapp} />
                </a>
            </div>
        </div>
    )
}

export default Header;