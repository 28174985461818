import React from "react";
import DetailCollection from "../../../component/detail-collection/DetailCollection";
import DiezyDetail1 from "../../../assets/diezy-detail-1.png";
import DiezyDetail2 from "../../../assets/diezy-detail-2.png";
import DiezyDetail3 from "../../../assets/diezy-detail-3.png";

function Diezy() {
    return(
        <DetailCollection 
            image1={DiezyDetail1}
            image2={DiezyDetail2}
            image3={DiezyDetail3}
            title={"Diezy (Diet Easy)"}
            description={"Diezy website is a website to help Indonesian people live healthily and maintain their diet. Diezy offers features such as exercise tutorials, information on required diet foods and tracking diet progress. In creating this website, I worked together with my friend to create the front end appearance of the website which was designed by a UI UX Designer."}
            year={"2023"}
            role={"Front End Developer"}
            tools={"visual studio code, html, css, javascript, react.js bootstrap, tailwind."}
            link={"#Diezy"}
            linkProjectClass={"hidden"}
        />
    )
}

export default Diezy;