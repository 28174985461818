import React from "react";
import Header from "../../component/header/Header";

function Contact() {
    return(
        <div>
            <Header 
            classContact={"active"}
            />
            <div className="h-[100%] text-[10vw] ml-[16vw] mt-[15vw]">
                COMING SOON
            </div>
        </div>
    )
}

export default Contact;