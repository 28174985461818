import React from "react";
import Header from "../../component/header/Header";
import Skill from "../../component/skill/Skill";
import "./Home.css";
import Profile from "../../assets/foto-profile.png";
import BackgroundSection2 from "../../assets/section2Home-bg.png";
import frontEndGambar from "../../assets/front-end.png";
import BackEndGambar from "../../assets/backend.png";
import FullStackGambar from "../../assets/full-stack.png";
import DownloadSymbol from "../../assets/download-symbol.png";
import CV from "../../assets/cv-raefandy-fadila.pdf";

function Home() {
  return (
    <div>
      <Header classHome={"active"} />
      <div className="home bg-[#EEEEEE] mt-[0.36vw]">
        {/* Section 1 */}
        <div className="section1 flex">
          <div className="pl-[5.75vw] max-w-[43.2vw] pt-[11vw]">
            <h2>HI, I’M RAEFANDY FADILA</h2>
            <div className="role-div">
              <h1>I’M A FULL STACK DEVELOPER</h1>
            </div>
            <div className="describe-div">
              <p>
                I have expertise in front-end and back-end development. My
                portfolio reflects a diverse range of projects that demonstrate
                creativity and efficiency in software development. Excited to
                continue growing in this industry.
              </p>
            </div>
          </div>
          <div className="pt-[3.6vw]">
            <img
              className="profile-picture"
              src={Profile}
              alt="profile picture"
            />
          </div>
        </div>

        {/* Section 2 */}
        <div className="section2">
          <img src={BackgroundSection2} className="section2-bg" />
          <div className="aboutme-div">
            <h1>About Me</h1>
            <p className="describe">
              I am very interested in technology and business. Therefore, I
              really like implementing technology in a business, such as
              creating websites or mobile app for business. I also have a high
              curiosity that makes me always want to learn with future
              technological developments.
            </p>
            <div className="">
              <a
                className="button-resume-div"
                href={CV}
                download="CV-Raefandy Fadila"
              >
                <a className="button-resume">
                  DOWNLOAD CV
                  <div className="download-symbol-div">
                    <img className="download-symbol" src={DownloadSymbol} />
                  </div>
                </a>
                {/* <div className="download-symbol-div"><img className="download-symbol" src={DownloadSymbol} /></div> */}
              </a>
              <a href="/project">
                <button href="/project" className="button-project">
                  Project
                </button>
              </a>
            </div>
          </div>
        </div>

        <div className="bg-[#E3E1DE] section3">
          <div className="skill-text">
            <p>SERVICES</p>
            <h1>Skill Set</h1>
            <div className="bg-[#1C658C] w-[9.35vw] h-[0.36vw]"></div>
          </div>

          <div className="flex skill-container">
            {/* <div className="skill-container-2"> */}
            <div className="column column-1">
              <Skill
                image={frontEndGambar}
                className={"skill-div"}
                title={"Front End Skill"}
                text={
                  "HTML, CSS, React.js/ts, Bootstrap, Javascript, Taildwind, react query, Material UI, and more"
                }
              />
            </div>
            <div className="column column-2">
              <Skill
                image={BackEndGambar}
                className={"skill-div"}
                title={"Back End Skill"}
                text={
                  "Node.js, Express.js, ejs, git, github, REST API,MySql,Mongodb"
                }
              />
            </div>
            {/* </div> */}
            {/* <div className="skill-container-3"> */}
            <div className="column column-3">
              <Skill
                image={FullStackGambar}
                className={"skill-div"}
                title={"Full Stack Skill"}
                text={"Combination of Front-End and Back-End, Version Control"}
              />
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
