import React from "react";
import DetailCollection from "../../../component/detail-collection/DetailCollection";
import DrumDetail1 from "../../../assets/drum-detail-1.png";

function DrumKit() {
    return(
        <DetailCollection 
        image1={DrumDetail1}
        image2={DrumDetail1}
        image3={DrumDetail1}
        title={"Drum Kit Website"}
        description={"A drum kit website is a website that can sound the same as a real drum. Users can press the buttons listed on the website page or can also press the keyboard to sound the drum according to the drum image that the user wants."}
        year={"2022"}
        role={"Full Stack Developer"}
        tools={"Atom, html, css, javascript,  jquery, font google."}
        link={"https://drumkit.rfstore.net"}
    />
    )
}

export default DrumKit;