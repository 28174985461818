import React from "react";
import DetailCollection from "../../../component/detail-collection/DetailCollection";
import AppAdmin1 from "../../../assets/appadmin-detail-1.png";
import AppAdmin2 from "../../../assets/appadmin-detail-2.png";
import AppAdmin3 from "../../../assets/appadmin-detail-3.png";

function AppAdmin() {
    return(
        <DetailCollection 
            image1={AppAdmin1}
            image2={AppAdmin2}
            image3={AppAdmin3}
            title={"App Admin"}
            description={"App Admin is a CRUD application for managing account, order and customer data for selling premium applications that can be shared with several customers. This application allows admins to view sales data, add customers, remind customers to renew via a system connected to WhatsApp, etc."}
            year={"2023"}
            role={"Full Stack Developer"}
            tools={"visual studio code, html, css, javascript, bootstrap, node.js, mysql, express.js, whatsapp link, ejs"}
            link={"https://appadmin.rfstore.net/admin"}
        />
    )
}

export default AppAdmin;