import React from "react";
import DetailCollection from "../../../component/detail-collection/DetailCollection";
import groodDetail1 from "../../../assets/groodDetail1.png";
import groodDetail2 from "../../../assets/groodDetail2.png";
import groodDetail3 from "../../../assets/groodDetail3.png";

export default function Grood() {
  return (
    <div>
      <DetailCollection
        image1={groodDetail1}
        image2={groodDetail2}
        image3={groodDetail3}
        title={"Grood"}
        description={
          "Grood website is an abbreviation of 'Growth' and 'Good' which is used to give feedback to colleagues. Grood is one of the forms of digitization on the annual feedback activities carried out by PT United Tractors. With Grood employees can give feedback anytime and anywhere through the grood website."
        }
        year={"2024"}
        role={"Front End Developer"}
        tools={
          "Visual Studio Code, html, css, javascript, Taildwind CSS, React Typescript, Material UI, React Query, Redux, Vite, Axios, etc"
        }
        link={"https://app.unitedtractors.com/grood"}
      />
    </div>
  );
}
