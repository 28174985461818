import React from "react";
import DetailCollection from "../../../component/detail-collection/DetailCollection";
import UniStoreDetail1 from "../../../assets/unistore-detail-1.png";
import UniStoreDetail2 from "../../../assets/unistore-detail-2.png";
import UniStoreDetail3 from "../../../assets/unistore-detail-3.png";

function UniStoreDetail() {
    return(
        <DetailCollection 
            image1={UniStoreDetail1}
            image2={UniStoreDetail2}
            image3={UniStoreDetail3}
            title={"Food Marketplace"}
            description={"Food marketplace is a web that serves online food ordering where users can order some food from tenants available on website pages. This website has guest, user, admin, and tenant roles, each role can have different access on the website. Each role can login with their email and pass. New users can register to get a customer role."}
            year={"2022"}
            role={"Full Stack Developer"}
            tools={"Visual Studio Code, html, css, javascript, bootstrap, jquery, PHP, AJAX, MySQL, Apache."}
            link={"https://unistore.rfstore.net"}
        />
    )
}

export default UniStoreDetail;