import React from "react";
import DetailCollection from "../../../component/detail-collection/DetailCollection";
import gasysDetail1 from "../../../assets/gasysDetail1.png";
import gasysDetail2 from "../../../assets/gasysDetail2.png";
import gasysDetail3 from "../../../assets/gasysDetail3.png";

export default function Webgasys() {
  return (
    <div>
      <DetailCollection
        image1={gasysDetail1}
        image2={gasysDetail2}
        image3={gasysDetail3}
        title={"WebGAsys"}
        description={
          "The GASys website is a general affair system used to manage various operational and administrative aspects of the company in United Tractors. The system includes inventory management, facility maintenance, asset management, logistics arrangements, as well as general administrations such as document and resource management."
        }
        year={"2024"}
        role={"Front End Developer"}
        tools={
          "Visual Studio Code, html, css, javascript, Taildwind CSS, React Typescript, Material UI, React Query, Redux, Vite, Axios, etc"
        }
        linkProjectClass={"hidden"}
      />
    </div>
  );
}
