import React from "react";
import DetailCollection from "../../../component/detail-collection/DetailCollection";
import EggDetail1 from "../../../assets/egg-detail-1.png";
import EggDetail2 from "../../../assets/egg-detail-2.png";
import EggDetail3 from "../../../assets/egg-detail-3.png";


function EggDetail() {
    return (
        <DetailCollection 
            image1={EggDetail1}
            image2={EggDetail2}
            image3={EggDetail3}
            title={"Egg Mini Store"}
            description={"Storeegg mini marketplace is a react.js-based website that users use to make purchases of several items. Users who have made a purchase can later see their items on the my product page. Users can also search for items they want to view or buy by typing in the searchbox and many other features such as: changing the view mode (Grid or List), accessing game menu subapps, coins integrated with user activity, item data integrated with the API, etc."}
            year={"2023"}
            role={"Full Stack Developer"}
            tools={"Visual Studio Code, html, css, typescript, bootstrap, react.js, axios, redux, redux-persist, react-router."}
            link={"https://egg.rfstore.net"}
        />
    );
}

export default EggDetail;