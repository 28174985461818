import React from "react";
import DetailCollection from "../../../component/detail-collection/DetailCollection";
import DiegoDetail1 from "../../../assets/diego-detail-1.png";
import DiegoDetail2 from "../../../assets/diego-detail-2.png";
import DiegoDetail3 from "../../../assets/diego-detail-3.png";

function Diego() {
    return(
        <DetailCollection 
            image1={DiegoDetail1}
            image2={DiegoDetail2}
            image3={DiegoDetail3}
            title={"Diego (Digital Era Go)"}
            description={"DIEGO is a mobile apps and website-based platform that provides business digitalization support services for MSMEs to market their products. The main aim of this business is to provide new opportunities for MSME players to reach a wider market."}
            year={"2023"}
            role={"Full Stack Developer"}
            tools={"visual studio code, html, css, javascript, react.js, bootstrap, tailwind, node.js, mysql, express.js."}
            link={"https://diego.rfstore.net"}
        />
    )
}

export default Diego;