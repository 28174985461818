import React from "react";
import "./Skill.css";

function Skill(props) {
  return (
    <div className={props.className}>
      <img src={props.image} alt="gambar front end" />
      <h1>{props.title}</h1>
      <p className="w-[50vw]">{props.text}</p>
    </div>
  );
}

export default Skill;
