import React from "react";
import Header from "../header/Header";
import Carousel from "react-bootstrap/Carousel";
import "./DetailCollection.css"

function DetailCollection(props) {
    return(
        <div className="detailCollection">
            <Header
                classProject={"active"}
            />
            <div className="section1 h-[auto] mb-[5vw]">
                <div className="carousel-frame">
                    <Carousel className="carousel" data-bs-theme="dark">
                        <Carousel.Item className="text-black">
                            <div className="w-full h-full border-[0.4vw] border-[#1C658C] rounded-[2.5vw]">
                                <img src={props.image1} />
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div>
                                <div className="w-full h-full border-[0.4vw] border-[#1C658C] rounded-[2.5vw]">
                                    <img src={props.image2} />
                                </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className="w-full h-full border-[0.4vw] border-[#1C658C] rounded-[2.5vw]">
                                <img src={props.image3} />
                            </div>
                        </Carousel.Item>
                    </Carousel>
                </div>

                <div className="flex describe-detail">
                    <div className="left-pane"> 
                        <h1>{props.title}</h1>
                        <div className="w-[40vw]">
                            <p>{props.description}</p>
                        </div>
                        <a href={props.link} target="_blank" className={props.linkProjectClass}>
                            <button>Link Project</button>
                        </a>
                    </div>
                    <div className="right-pane">
                        <div className="flex top-div-group">
                            <div className="top-div year-div">
                                <h1>Year</h1>
                                <p>{props.year}</p>
                            </div>
                            <div className="top-div role-div">
                                <h1>Role/Position</h1>
                                <p>{props.role}</p>
                            </div>
                        </div>
                        <div className="bottom-div">
                            <h1>Tools</h1>
                            <p>{props.tools}</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default DetailCollection;