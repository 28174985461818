import React from "react";
// import './App.css';
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Home from "./pages/home/Home";
import Resume from "./pages/resume/Resume";
import Contact from "./pages/contact/Contact";
import Project from "./pages/project/Project";
import Diego from "./pages/detailProject/diego/Diego";
import EggDetail from "./pages/detailProject/eggMiniStore/EggDetail";
import GoogleKeepClone from "./pages/detailProject/googleKeepClone/GoogleKeepClone";
import UniStoreDetail from "./pages/detailProject/uniStore/UniStoreDetail";
import SimonGame from "./pages/detailProject/simonGame/SimonGame";
import DrumKit from "./pages/detailProject/drumKit/DrumKit";
import Diezy from "./pages/detailProject/diezy/Diezy";
import Grood from "./pages/detailProject/grood/Grood";
import AppAdmin from "./pages/detailProject/appAdmin/AppAdmin";
import Webgasys from "./pages/detailProject/webgasys/Webgasys";


function App() {
  return (
    // Halaman 1    
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/project" element={<Project />} />
          <Route path="/project/egg-mini-store" element={<EggDetail />} />
          <Route path="/project/diego" element={<Diego />} />
          <Route path="/project/app-admin" element={<AppAdmin />} />
          <Route path="/project/google-keep-clone" element={<GoogleKeepClone />} />
          <Route path="/project/uni-store" element={<UniStoreDetail />} />
          <Route path="/project/simon-game" element={<SimonGame />} />
          <Route path="/project/drum-kit" element={<DrumKit />} />
          <Route path="/project/diezy" element={<Diezy />} />
          <Route path="/project/grood" element={<Grood />} />
          <Route path="/project/webgasys" element={<Webgasys />} />
        </Routes>
      </Router>
  );
}

export default App;