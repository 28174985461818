import React from "react";
import "./Collections.css";

function Collection(props) {
  return (
    <div className="collection-component" class={props.className}>
      <img className="w-full h-full rounded-3xl" src={props.image} />
      <h1>{props.title}</h1>
      <p>{props.description}</p>
    </div>
  );
}

export default Collection;
